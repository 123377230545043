import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    signal,
    ViewChild,
    ViewEncapsulation,
    WritableSignal,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { EventPricingData, lineItemType } from "../../pojo/Pricing";
import { Table } from "../s25-table/Table";
import { GenericTableSimpleSelectComponent } from "../s25-table/generics/generic.table.simple.select.component";
import { S25PricingUtil } from "./s25.pricing.util";
import { Bind } from "../../decorators/bind.decorator";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { S25Util } from "../../util/s25-util";
import { PricingService } from "../../services/pricing.service";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { S25DatepickerComponent } from "../s25-datepicker/s25.datepicker.component";

@TypeManagerDecorator("modal-convert-invoice")
@Component({
    selector: "modal-convert-invoice",
    template: `
        <p class="c-margin-bottom--single">{{ modeInfoMessage }}</p>
        @if (showInvoiceConvertData) {
            <s25-ng-checkbox
                [modelValue]="createStandardInvoice()"
                (modelValueChange)="createStandardInvoice.set($event); getAdjustmentData()"
                >Create Invoice for Standard Pricing</s25-ng-checkbox
            >
            <s25-loading-inline [model]="{}"></s25-loading-inline>
            @if (createStandardInvoice() && this.numAdjustments() > 0) {
                <p class="ngFinePrint c-margin-top--quarter c-margin-bottom--half">
                    In order to facilitate use of <b>Payment Mode</b>, event-level adjustments can be converted into
                    <b>Manual Payments</b> on the invoice copy of Standard Pricing. For each adjustment, please indicate
                    below whether to make this conversion, carry it over to the invoice, or exclude it.
                </p>
                <s25-ng-table [dataSource]="tableConfig"></s25-ng-table>
            }
        }
    `,
    styles: `
        s25-loading-inline {
            display: block;
            width: fit-content;
            margin: 1em auto 0;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class ModalConvertInvoiceComponent implements OnInit {
    @Input({ required: true }) modeInfoMessage: string;
    @Input({ required: true }) showInvoiceConvertData: boolean;
    @Input({ required: true }) combineRelatedEvents: boolean;
    @Input({ required: true }) eventId: number;
    @Input() dateFormat: string;
    @Input() createStandardInvoice!: WritableSignal<boolean>;
    @Input() selectionsValidated!: WritableSignal<boolean>;
    @Input() showErrors!: WritableSignal<boolean>;

    @Output() updateMappingSelections: EventEmitter<EventPricingData> = new EventEmitter<EventPricingData>();

    @ViewChild(S25TableComponent) mapTable: S25TableComponent;

    tableConfig: Table.DataSource;
    selectedPaymentTypes: { [key: number]: PaymentMap } = {};
    numAdjustments = signal<number>(null);
    eventPricingData: EventPricingData;
    errorMap = signal<{ [key: number]: boolean }>({});

    constructor(private elementRef: ElementRef) {}

    async ngOnInit() {
        await this.getAdjustmentData();
    }

    setColumns() {
        return [
            { id: "adj", header: "Adjustment" },
            { id: "amt", header: "Amount" },
            { id: "action", header: "Action" },
            ...(Object.values(this.selectedPaymentTypes).some((payment) => S25Util.isDefined(payment.type))
                ? [
                      { id: "payment", header: "Payment Type" },
                      { id: "date", header: "Due Date" },
                      { id: "status", header: "Status" },
                  ]
                : []),
            ...(Object.values(this.selectedPaymentTypes).some((payment) => payment.status === "paid")
                ? [{ id: "paidDate", header: "Date Paid" }]
                : []),
        ];
    }

    @Bind
    async getRows() {
        const rows =
            this.eventPricingData?.bill_item
                ?.filter((adj) => adj.bill_item_type_id === lineItemType.ADJUSTMENT)
                ?.map((adj, index) => {
                    adj.paymentMapAction ??= { action: "map" };
                    const errorSignal = computed(
                        () => this.errorMap()[index] && this.showErrors() && "Please select a payment type",
                    );
                    return {
                        id: `${adj.charge_to_id}-${index + 1}`,
                        name: adj.adjustment_name ?? `${adj.charge_to_name}-${index + 1}`,
                        cells: {
                            adj: { text: adj.adjustment_name ?? "No Name Provided" },
                            amt: {
                                text: adj.adjustment_percent
                                    ? `${adj.adjustment_percent * 100}%`
                                    : S25PricingUtil.formatCurrency(adj.adjustment_amt),
                            },
                            action: {
                                component: GenericTableSimpleSelectComponent,
                                inputs: {
                                    modelValue: adj.paymentMapAction.action ?? "map",
                                    options: [
                                        { value: "map", label: "Map To Invoice" },
                                        { value: "convert", label: "Convert To Payment" },
                                        { value: "exclude", label: "Exclude From Invoice" },
                                    ],
                                },
                                outputs: {
                                    modelValueChange: (action: "map" | "convert" | "exclude") => {
                                        adj.paymentMapAction = { action };
                                        const prevChoice = this.selectedPaymentTypes[index]?.type;
                                        this.selectedPaymentTypes[index] = { type: action === "convert" ? "" : null };
                                        if (prevChoice !== this.selectedPaymentTypes[index]?.type)
                                            this.updateTableConfig();
                                        this.selectionsValidated.set(this.validate());
                                        this.updateMappingSelections.emit(this.eventPricingData);
                                    },
                                },
                            },
                            ...(S25Util.isDefined(this.selectedPaymentTypes[index]?.type) && {
                                payment: {
                                    component: GenericTableSimpleSelectComponent,
                                    inputs: {
                                        modelValue: this.selectedPaymentTypes[index]?.type ?? "",
                                        options: this.getPaymentTypeOptions(index),
                                        errorMsg: errorSignal,
                                    },
                                    outputs: {
                                        modelValueChange: (type: "misc" | "deposit" | "final") => {
                                            adj.paymentMapAction.type = type;
                                            this.selectedPaymentTypes[index].type = type;
                                            if (this.numAdjustments() > 0) this.updateTableConfig();
                                            this.selectionsValidated.set(this.validate());
                                            this.updateMappingSelections.emit(this.eventPricingData);
                                        },
                                    },
                                },
                                date: {
                                    component: S25DatepickerComponent,
                                    inputs: {
                                        modelValue: {
                                            date: this.selectedPaymentTypes[index]?.dueDate ?? new Date(),
                                            dateFormat: this.dateFormat,
                                        },
                                    },
                                    outputs: {
                                        modelValueChange: (date: string) => {
                                            this.selectedPaymentTypes[index].dueDate = date;
                                            adj.paymentMapAction.dueDate = date;
                                        },
                                    },
                                },
                                status: {
                                    component: GenericTableSimpleSelectComponent,
                                    inputs: {
                                        modelValue: this.selectedPaymentTypes[index]?.status ?? "unpaid",
                                        options: [
                                            { value: "unpaid", label: "Unpaid" },
                                            { value: "paid", label: "Paid" },
                                        ],
                                    },
                                    outputs: {
                                        modelValueChange: (status: "unpaid" | "paid") => {
                                            this.selectedPaymentTypes[index].status = status;
                                            adj.paymentMapAction.status = status;
                                            this.updateTableConfig();
                                        },
                                    },
                                },
                                ...(this.selectedPaymentTypes[index]?.status === "paid" && {
                                    paidDate: {
                                        component: S25DatepickerComponent,
                                        inputs: {
                                            modelValue: {
                                                date: this.selectedPaymentTypes[index]?.datePaid ?? new Date(),
                                                dateFormat: this.dateFormat,
                                            },
                                        },
                                        outputs: {
                                            modelValueChange: (date: string) => {
                                                this.selectedPaymentTypes[index].datePaid = date;
                                                adj.paymentMapAction.datePaid = date;
                                            },
                                        },
                                    },
                                }),
                            }),
                        },
                    };
                }) ?? [];
        return { rows };
    }

    @Bind
    initTableConfig() {
        this.tableConfig = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: this.setColumns(),
        };
    }

    @Bind
    updateTableConfig() {
        this.initTableConfig();
        this.mapTable.dataSource = this.tableConfig;
        return this.mapTable.ngOnInit();
    }

    //only one of each payment type can be created - if one is selected, update options for other adjustments
    getPaymentTypeOptions(index: number) {
        const paymentTypeOptions = [
            { value: "misc", label: "Misc" },
            { value: "deposit", label: "Deposit" },
            { value: "final", label: "Final" },
        ];
        const selectedOption =
            S25Util.isDefined(this.selectedPaymentTypes[index]?.type) &&
            paymentTypeOptions.find((option) => option.value === this.selectedPaymentTypes[index].type);
        const firstOption = selectedOption || { value: "", label: "Select Type", disabled: true };
        const otherOptions = paymentTypeOptions.filter(
            (option) =>
                !Object.values(this.selectedPaymentTypes)
                    ?.map((payment) => payment.type)
                    ?.includes(option.value as PaymentMap["type"]),
        );
        return [firstOption, ...otherOptions];
    }

    async getAdjustmentData() {
        if (this.createStandardInvoice() && this.showInvoiceConvertData) {
            S25LoadingApi.init(this.elementRef.nativeElement);
            this.eventPricingData = await PricingService.getPricing(this.eventId, this.combineRelatedEvents);
            this.numAdjustments.set(
                this.eventPricingData.bill_item?.filter((adj) => adj.bill_item_type_id === lineItemType.ADJUSTMENT)
                    ?.length ?? 0,
            );
            this.initTableConfig();
            S25LoadingApi.destroy(this.elementRef.nativeElement);
        }
    }

    @Bind
    validate() {
        this.eventPricingData.bill_item
            ?.filter((adj) => adj.bill_item_type_id === lineItemType.ADJUSTMENT)
            ?.map((adj, index) => {
                adj.paymentMapAction ??= { action: "map" };
                this.errorMap.update((errorMap) => ({
                    ...errorMap,
                    //only error occurs if no payment type selected
                    [index]: adj.paymentMapAction.action === "convert" && !adj.paymentMapAction.type,
                }));
                return adj;
            });
        return !Object.values(this.errorMap()).some(Boolean);
    }
}

type PaymentMap = {
    type: "misc" | "deposit" | "final" | "";
    dueDate?: string;
    status?: "unpaid" | "paid";
    datePaid?: string;
};
